import React from 'react';
import styled from 'styled-components';

import { H3, H4, P, Bold } from '../../../components/typography';
import { Container, Content, Image, Copy, CustomNavButton } from './components';

const bookImage = require('./a-grander-story.jpg');

const header = {
  color: 'black',
  marginBottom: 32
};

const pStyle = {
  color: 'black',
  marginBottom: 16
};

const IndentCopy = styled(P)`
  margin-left: 30px;

  @media ${p => p.theme.media.largePhone} {
    margin-left: ${p => (p.quoteGuy ? 20 : 0)}px;
  }
`;

const AGranderStory = () => (
  <Container>
    <Content>
      <Image src={bookImage} alt="A Grander Story" />
    </Content>
    <Content>
      <Copy>
        <H3 {...header}>
          How do we faculty members thrive in God’s grander story?
        </H3>
        <H4 {...header}>
          <i>A Grander Story: An Invitation to Christian Professors</i> answers
          this question by providing a biblical understanding of a professor’s
          identity in Christ and unique calling to Christian scholarship,
          teaching, and department life.
        </H4>
        <P {...pStyle}>
          By showcasing a theology of the grander story, six faculty narratives,
          resources, and discussion questions along the way,
          <i> A Grander Story</i> both inspires and equips Christian faculty to
          think differently about their lives and work.
        </P>
        <div style={{ flexDirection: 'row', marginBottom: 30 }}>
          <Bold {...pStyle} marginBottom={0}>
            Request a <i>free</i> copy{' '}
          </Bold>
          <CustomNavButton to="/a-grander-story" text="here!" />
        </div>
        <IndentCopy {...pStyle}>
          <i>
            This book is a celebration of our high calling as Christ followers
            on the university campus . . . Read this book to be uplifted, to
            find your place in God’s grand story, to know you are not alone, to
            integrate your faith and work, and to be equipped for ministry. Then
            give a copy to every Christian professor you know
          </i>
        </IndentCopy>
        <IndentCopy quoteGuy {...pStyle}>
          – Charles M. C. Lee Moghadam Family Professor of Management Stanford
          Graduate School of Business
        </IndentCopy>
      </Copy>
    </Content>
  </Container>
);

export default AGranderStory;
