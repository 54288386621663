import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { P, customUnderline } from '../../components/typography';
import Form from '../../components/Form';

const Info = styled.div`
  background-color: ${p => p.theme.color.white};
  flex-direction: column;
  width: 48%;

  @media ${p => p.theme.media.tablet} {
    width: 100%;
  }
`;

const Wrapper = styled.div`
  justify-content: space-between;

  ${p => p.theme.size.padding()};

  @media ${p => p.theme.media.tablet} {
    flex-direction: column;
  }
`;

const Email = styled.a`
  color: ${p => p.theme.color.black};
  text-decoration: none;
  ${p => customUnderline(p.theme.color.black)}
`;

const FormPage = ({ copy, fields, mailTo, prefix, title, type }) => (
  <Wrapper>
    <Info>
      {title}
      {copy && (
        <P color="black" marginBottom={16}>
          {copy}
        </P>
      )}
      <P color="black" marginBottom={32}>
        {prefix}
        <Email href={mailTo}>scott.talley@cru.org</Email>
      </P>
    </Info>
    <Info>
      <Form fields={fields} type={type} />
    </Info>
  </Wrapper>
);

FormPage.propTypes = {
  copy: PropTypes.string,
  fields: PropTypes.object.isRequired,
  mailTo: PropTypes.string.isRequired,
  prefix: PropTypes.string,
  title: PropTypes.node.isRequired,
  type: PropTypes.string.isRequired
};

export default FormPage;
