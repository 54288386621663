import styled, { css } from 'styled-components';

const commonStyles = css`
  color: ${p => p.theme.color[p.color] || p.theme.color.white};
  margin-bottom: ${p => p.marginBottom || 0}px;
  white-space: pre-wrap;
  text-decoration: none;
`;

export const H1 = styled.h1`
  ${commonStyles};
  font-family: ${p => p.theme.font.poppinsBold};
  font-size: 4rem;
  word-wrap: break-word;
  line-height: 1.2;
  margin-bottom: 34px;

  @media ${p => p.theme.media.largePhone} {
    font-size: 2.5rem;
  }
`;

export const H2 = styled.h2`
  ${commonStyles};
  font-family: ${p => p.theme.font.poppinsRegular};
  font-size: 2.8rem;
  line-height: 1.3;

  @media ${p => p.theme.media.largePhone} {
    font-size: 1.8rem;
  }
`;

export const H3 = styled.h3`
  ${commonStyles};
  font-family: ${p => p.theme.font.poppinsRegular};
  font-size: 2.2rem;
  line-height: 1.3;

  @media ${p => p.theme.media.largePhone} {
    font-size: 1.2rem;
  }
`;

export const H4 = styled.h4`
  ${commonStyles};
  font-family: ${p => p.theme.font.poppinsRegular};
  font-size: 1.2rem;
  line-height: 1.4;

  @media ${p => p.theme.media.largePhone} {
    font-size: 1rem;
  }
`;

export const P = styled.p`
  ${commonStyles};
  font-family: ${p => p.theme.font.poppinsLight};
  font-size: ${p => (p.large ? 1.4 : 1)}rem;
  line-height: 1.8;

  @media ${p => p.theme.media.largePhone} {
    font-size: ${p => (p.large ? 1.5 : 1)}rem;
  }
`;

export const Bold = styled.p`
  ${commonStyles};
  font-family: ${p => p.theme.font.poppinsBold};
`;

export function customUnderline(color = '#FFF') {
  return {
    backgroundImage: `linear-gradient(
    to right,
    ${color} 100%,
    ${color} 0
  )`,
    backgroundPosition: '0 100%',
    backgroundRepeat: 'repeat-x',
    backgroundSize: '1px 1px',
    outlineWidth: '0px',
    textDecoration: 'none',
    whiteSpace: 'initial'
  };
}
