import styled, { css } from 'styled-components';

const inverse = css`
  background-color: ${p => p.theme.color.white};
  color: ${p => p.theme.color.blue};
`;

const large = css`
  font-family: ${p => p.theme.font.poppinsMedium};
  font-size: 18px;
  letter-spacing: 0.02rem;
  padding: 20px 32px;
`;

export const Wrapper = styled.div.withConfig({
  shouldForwardProp: p => p !== 'inverse'
})`
  align-items: center;
  background-color: ${p => p.theme.color.blue};
  color: ${p => p.theme.color.white};
  cursor: pointer;
  justify-content: center;
  text-decoration: none;
  width: auto;

  &:hover {
    opacity: 0.8;
  }

  ${p => p.inverse && inverse};
`;

export const Text = styled.p`
  flex-direction: column;
  font-family: ${p => p.theme.font.poppinsLight};
  justify-content: center;
  padding: 14px 25px;

  ${p => p.large && large};
`;
