import styled from 'styled-components';

import { P } from '../typography';

export const Item = styled(P).attrs(p => {
  return {
    color: 'black',
    large: window.innerWidth > p.theme.deviceWidth.largePhone
  };
})`
  width: 90%;

  @media ${p => p.theme.media.largePhone} {
    width: 100%;
  }
`;

export const Content = styled.div`
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 50%;

  @media ${p => p.theme.media.tablet} {
    width: 100%;
  }
`;

export const Container = styled.div`
  ${p => p.theme.size.padding()};

  justify-content: space-between;
`;

export const Image = styled.img`
  height: 470px;

  @media ${p => p.theme.media.tablet} {
    display: none;
  }
`;
