import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Input from '../fields/Input';
import { FieldSet } from '../fields/components';
import { P, H2 } from '../typography';
import InputButton from '../fields/InputButton';
import TextArea from '../fields/TextArea';
import Radio from '../fields/Radio';

import {
  isAcceptableEmail,
  isAcceptableString,
  isNotNull
} from '../fields/utils';
import send from './send';

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
`;

const NameWrapper = styled.div`
  flex-direction: row;

  @media ${p => p.theme.media.largePhone} {
    flex-direction: column;
  }
`;

const NameInput = styled(Input)`
  margin-right: ${p => (p.isFirst ? 20 : 0)}px;
  width: 50%;

  @media ${p => p.theme.media.largePhone} {
    margin-right: 0px;
    width: 100%;
  }
`;

const SentMsg = styled.div`
  background-color: white;
  bottom: 0;
  height: 100%;
  max-height: 0px;
  opacity: 0;
  position: absolute;
  transform-origin: bottom;
  transition: opacity 0.2s, max-height 0.5s ease-in;
  width: 100%;
  z-index: -1;

  @media ${p => p.theme.media.largePhone} {
    flex-direction: column-reverse;
    top: 0;
  }

  ${p =>
    p.success &&
    css`
      max-height: 1000px;
      opacity: 1;
      z-index: 0;
    `};
`;

function validateForm(form, updateIsValid) {
  const stuff = Object.keys(form).map(field => {
    let validator;
    if (field === 'email') validator = isAcceptableEmail;
    else if (field === 'freeCopy') validator = isNotNull;
    else validator = isAcceptableString;

    const value = validator(form[field].value);
    updateIsValid(field, value);
    return value;
  });

  return stuff.every(isValid => isValid === true);
}

const Form = ({ fields, type }) => {
  const [form, setForm] = useState(fields);
  const [success, setSuccess] = useState(false);

  function updateIsValid(field, isValid) {
    setForm(form => ({ ...form, [field]: { ...form[field], isValid } }));
  }

  function handleChange(field, value) {
    setForm(form => ({ ...form, [field]: { ...form[field], value } }));
  }

  async function onSubmit(e) {
    e.preventDefault();
    if (validateForm(form, updateIsValid)) {
      setSuccess(await send(form, type));
    }
  }

  return (
    <FormWrapper
      autoComplete="true"
      onSubmit={onSubmit}
      noValidate
      success={success}
    >
      {/* Both Forms */}
      <FieldSet>
        <P
          as="legend"
          color={
            !form.firstName.isValid || !form.lastName.isValid
              ? 'error'
              : 'black'
          }
        >
          Name *
        </P>
        <NameWrapper>
          <NameInput
            onChange={handleChange}
            isFirst
            name="First Name"
            field="firstName"
            isBottom
            isRequired
            hasError={!form.firstName.isValid}
          />
          <NameInput
            onChange={handleChange}
            name="Last Name"
            field="lastName"
            isBottom
            isRequired
            hasError={!form.lastName.isValid}
          />
        </NameWrapper>
      </FieldSet>
      <Input
        onChange={handleChange}
        name="Email"
        field="email"
        type="email"
        placeholder="email@example.com"
        isRequired
        hasError={!form.email.isValid}
      />
      {/* Contact Form */}
      {fields.message && (
        <TextArea
          field="message"
          onChange={handleChange}
          name="Message"
          isRequired
          hasError={!form.message.isValid}
        />
      )}
      {/* Grander Story Form */}
      {fields.department && (
        <Input
          onChange={handleChange}
          name="Department"
          field="department"
          placeholder="Biology Department at UK"
          isRequired
          hasError={!form.department.isValid}
        />
      )}
      {fields.freeCopy && (
        <Radio
          onChange={handleChange}
          name="Want to request a Free Copy of 'A Grander Story'?"
          isRequired
          field="freeCopy"
          options={[
            { item: 'Yes!', value: true },
            { item: 'No', value: false }
          ]}
          hasError={!form.freeCopy.isValid}
        />
      )}
      <InputButton text="Send" />
      <SentMsg color="blue" success={success}>
        <H2 color="blue">Request Sent!</H2>
      </SentMsg>
    </FormWrapper>
  );
};

Form.propTypes = {
  fields: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired
};

export default Form;
