import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';

import GlobalStyle from './theme/global';
import theme from './theme';
import Page from './pages';

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <Page />
  </ThemeProvider>,
  document.getElementById('root')
);
