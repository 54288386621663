import React from 'react';
import styled from 'styled-components';

import CustomHero from '../../components/CustomHero';
import { H2 } from '../../components/typography';
import EventCard from './EventCard';

import events from './events';

const StyledCustomHero = styled(CustomHero)`
  height: 50vh;
  justify-content: center;
  align-items: center;

  @media ${p => p.theme.media.tall} {
    height: 10vh;
  }
`;

const sand = require('./hero.jpg');

const today = new Date();

// sort and filter events
const nextEvents = events
  .reduce((acc, cur) => {
    if (cur.date > today) {
      return [...acc, cur];
    }
    return acc;
  }, [])
  .sort((a, b) => (a.date > b.date ? 1 : -1));

const Events = () => (
  <>
    <StyledCustomHero src={sand}>
      <H2>
        {nextEvents.length
          ? 'Upcoming events.'
          : 'Check back for future events.'}
      </H2>
    </StyledCustomHero>
    {nextEvents.map((event, index) => (
      <EventCard
        key={event.name}
        event={event}
        last={index === nextEvents.length - 1}
      />
    ))}
  </>
);

export default Events;
