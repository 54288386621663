import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { P, H2 } from '../../../components/typography';
import Button from '../../../components/Button';
import DateBox from './DateBox';
import 'pattern.css/dist/pattern.min.css';
// https://bansal.io/pattern-css for background patterns

const Title = styled(H2)`
  font-size: 28px;
`;

const Text = styled(P).attrs({ color: 'black' })`
  margin-bottom: ${p => (p.margin ? 16 : 0)}px;
`;

const Row = styled.div`
  flex-direction: row;
`;

const Container = styled.div`
  justify-content: center;

  ${p => p.theme.size.padding()};

  @media ${p => p.theme.media.largePhone} {
    padding: 0 0 ${p => (p.last ? 0 : 32)}px;
  }
`;

const RowContainer = styled.div`
  flex-direction: row;
  padding: 10px 10px 30px 10px;
  width: 80%;
  color: rgb(42, 40, 41, 0.1);
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
  position: relative;

  @media ${p => p.theme.media.tablet} {
    width: 100%;
    padding: 20px;
  }
`;

const Wrapper = styled.div`
  flex-direction: column;
  width: 100%;
  position: relative;
  background-color: ${p => p.theme.color.white};
  border: 2px solid ${p => p.theme.color.blue};
  box-shadow: 7px 7px 0px ${p => p.theme.color.blue};
  padding: 20px;
  margin: 12px 20px 0 0;

  @media ${p => p.theme.media.largePhone} {
    box-shadow: none;
    margin: 0;
  }
`;

const EventCard = ({ event, last }) => {
  const {
    calendar,
    date,
    desc,
    ics,
    location,
    map,
    name,
    pattern,
    register,
    time
  } = event;
  return (
    <Container last={last}>
      <RowContainer className={pattern}>
        <DateBox date={date} />
        <Wrapper>
          <Title color="black" marginBottom={16}>
            {name}
          </Title>
          <Text>
            {date.toLocaleDateString('en-US', { month: 'short' })}{' '}
            {date.getDate()}
          </Text>
          <Text>{time}</Text>
          <Row>
            <Text>
              {location}
              <Text as="a" href={map} target="_blank">
                {' (map)'}
              </Text>
            </Text>
          </Row>
          <Row>
            <Text margin as="a" href={calendar} target="_blank">
              Google Calendar
            </Text>
            <Text> · </Text>
            <Text margin as="a" href={ics}>
              ICS
            </Text>
          </Row>
          <Text margin>{desc}</Text>
          <Button large href={register} text="Register →" />
        </Wrapper>
      </RowContainer>
    </Container>
  );
};

EventCard.propTypes = {
  last: PropTypes.number.isRequired,
  event: PropTypes.shape({
    calendar: PropTypes.string.isRequired,
    date: PropTypes.object.isRequired,
    desc: PropTypes.string.isRequired,
    ics: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    map: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    pattern: PropTypes.string.isRequired,
    register: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired
  })
};

export default EventCard;
