import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import NavButton from '../NavButton';
import Button from '../Button';
import { P } from '../typography';

const Wrapper = styled.div`
  align-items: center;
  background-color: ${p => p.theme.color.white};
  flex-direction: column;
  overflow: hidden;
  z-index: 1;
  flex: 1 0 auto;

  height: ${p => (p.isOpen ? 'calc(100vh - 85px)' : '0px')};
  justify-content: center;
  opacity: ${p => (p.isOpen ? 1 : 0)};
  transition: visibility 0.5s, opacity 0.5s, height 0.5s ease-in-out;

  @media ${p => p.theme.media.short} {
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
    margin-top: ${p => (p.isOpen ? 32 : 0)}px;
  }
`;

const Give = styled(Button)`
  margin-top: 20px;

  @media ${p => p.theme.media.short} {
    margin-top: 0;
    margin-left: 20px;
  }
`;

const Menu = ({ onClick, isOpen }) => (
  <Wrapper id="menu" isOpen={isOpen}>
    <NavButton to="/" exact onClick={onClick}>
      <P large color="blue">
        Home
      </P>
    </NavButton>
    <NavButton to="/about" onClick={onClick}>
      <P large color="blue">
        About
      </P>
    </NavButton>
    <NavButton to="/contact-us" onClick={onClick}>
      <P large color="blue">
        Contact Us
      </P>
    </NavButton>
    <NavButton to="events" onClick={onClick}>
      <P large color="blue">
        Events
      </P>
    </NavButton>
    <Give large text="Give" href="https://give.cru.org/1076830" />
  </Wrapper>
);

Menu.propTypes = {
  onClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool
};

export default Menu;
