import styled from 'styled-components';

import { customUnderline, H2 } from '../typography';

export const Container = styled.div`
  background-color: ${p => p.theme.color.darkGrey};

  ${p => p.theme.size.padding()};

  @media ${p => p.theme.media.tablet} {
    flex-direction: column;
  }

  @media ${p => p.theme.media.largePhone} {
    padding: 32px ${p => p.theme.size.sidePadding} 0px;
  }
`;

export const SingleCard = styled.div`
  flex-direction: column;
  width: 33%;
  padding: 90px ${p => (p.outer ? 0 : 32)}px;

  @media ${p => p.theme.media.tablet} {
    width: 100%;
    padding: 0px;
    margin-bottom: 40px;
  }
`;

export const Title = styled(H2)`
  margin-bottom: 72px;

  @media ${p => p.theme.media.tablet} {
    margin-bottom: 10px;
  }
`;

export const To = styled.a`
  ${p => customUnderline(p.theme.color.white)};
  color: ${p => p.theme.color.white};

  &:visited {
    color: ${p => p.theme.color.white};
  }
`;
