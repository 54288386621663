import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { P } from '../../../components/typography';
import 'pattern.css/dist/pattern.min.css';

const BOX_SIZE = '70px';

const Box = styled.div`
  align-items: center;
  background-color: ${p => p.theme.color.white};
  border: 2px solid ${p => p.theme.color.blue};
  box-shadow: 7px 7px 0px ${p => p.theme.color.blue};
  flex-direction: column;
  height: ${BOX_SIZE};
  justify-content: center;
  position: relative;
  width: ${BOX_SIZE};
`;

const Month = styled(P)`
  font-size: 14px;
  color: ${p => p.theme.color.blue};
`;

const Day = styled(Month)`
  font-size: 28px;
  line-height: 1;
`;

const Container = styled.div`
  flex-direction: column;
  padding: 5px 32px 0 5px;
  position: relative;

  @media ${p => p.theme.media.largePhone} {
    display: none;
  }
`;

const DateBox = ({ date }) => {
  return (
    <Container>
      <Box>
        <Month color="darkGrey">
          {date.toLocaleDateString('en-US', { month: 'short' })}
        </Month>
        <Day color="darkGrey">{date.getDate()}</Day>
      </Box>
    </Container>
  );
};

DateBox.propTypes = {
  date: PropTypes.object.isRequired
};

export default DateBox;
