export const color = {
  blue: '#253551',
  white: '#fff',
  offWhite: '#fafafa',
  black: '#000',
  grey: '#e0e0db',
  darkGrey: '#2a2829',
  error: '#cc3b3b'
};

export const deviceWidth = {
  mobilePhone: '450px',
  largePhone: 750,
  tablet: '800px',
  desktop: '1000px'
};

export const font = {
  poppinsBold: 'poppinsBold',
  poppinsLight: 'poppinsLight',
  poppinsRegular: 'poppinsRegular',
  poppinsMedium: 'poppinsMedium'
};

export const media = {
  desktop: `(min-width: 1000px)`,
  wide: `(min-width: 1920px)`,
  tablet: `(max-width: 950px)`,
  largePhone: `(max-width: 750px)`,
  tall: `(min-height: 1000px)`,
  short: `(max-height: 460px)`
};

export const size = {
  sidePadding: '3vw',
  boxPadding: 'calc(6.6 * (1vw + 1vh - 1vmin)) 3vw',
  headerHeight: 116,
  padding: (height = 3) => `padding: ${height}vh 3vw;
  @media ${media.wide} {
    padding: ${height}vh 15vw;
  }`
};

export default {
  color,
  deviceWidth,
  font,
  size,
  media
};
