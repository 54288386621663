import React from 'react';
import styled from 'styled-components';

import { customUnderline } from '../typography';
import NavButton from '../NavButton';

const Container = styled.footer`
  display: flex;
  background-color: ${p => p.theme.color.darkGrey};
  flex-direction: column;

  ${p => p.theme.size.padding()};
`;

const NavLink = styled(NavButton).attrs({
  linkColor: 'white',
  noActiveStyle: true
})`
  align-self: flex-end;
  ${p => customUnderline(p.theme.color.white)};
`;

const Footer = () => (
  <Container>
    <NavLink text="Home" to="/" exact />
    <NavLink text="About" to="/about" />
    <NavLink text="Contact Us" to="/contact-us" />
    <NavLink text="Give" href="https://give.cru.org/1076830" />
  </Container>
);

export default Footer;
