import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Wrapper } from '../../Button/components';

const Button = styled(Wrapper)`
  height: 60px;
  font-family: ${p => p.theme.font.poppinsLight};
  text-align: center;
  width: 100px;
  font-size: 1rem;
  border: none;
  outline: none;
`;

const InputButton = ({ text }) => (
  <Button as="input" value={text} type="submit" />
);

InputButton.propTypes = {
  text: PropTypes.string.isRequired
};

export default InputButton;
