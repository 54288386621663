import styled from 'styled-components';

import { H1, H2, Bold } from '../../components/typography';

export const Wrapper = styled.div`
  padding: ${p => p.theme.size.boxPadding};
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  align-self: center;
  text-align: center;
`;

export const Number = styled(H1)`
  font-size: 90px;
  margin: 0px;
  line-height: 1;
`;

export const NumbersRow = styled.div`
  flex-direction: row;
  width: 218px;
  justify-content: space-between;
  position: relative;
  text-align: center;
  margin-bottom: 32px;
`;

export const Book = styled(H2)`
  letter-spacing: 3px;
`;

export const Zero = styled(Number)`
  position: absolute;
  left: 0;
  right: 0;
  opacity: 0.05;
`;

export const Verse = styled(Bold)`
  font-size: 32px;
  max-width: 50%;

  @media ${p => p.theme.media.tablet} {
    max-width: 75%;
  }
`;
