import React from 'react';
import styled from 'styled-components';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Header from '../components/Header';
import Footer from '../components/Footer';

import Home from './Home';
import About from './About';
import Contact from './Contact';
import Events from './Events';
import FourOFour from './FourOFour';
import GranderStory from './GranderStory';

const Container = styled.div`
  display: flex;
  flex-flow: column;
  min-width: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1 0 auto;

  @media ${p => p.theme.media.desktop} {
    margin-top: ${p => p.theme.size.headerHeight}px;
  }
`;

const Page = () => (
  <Router basename="/">
    <Container>
      <Header />
      <Content>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/about" component={About} />
          <Route path="/contact-us" component={Contact} />
          <Route path="/events" component={Events} />
          <Route path="/events" component={Events} />
          <Route path="/a-grander-story" component={GranderStory} />
          <Route component={FourOFour} />
        </Switch>
      </Content>
      <Footer />
    </Container>
  </Router>
);

export default Page;
