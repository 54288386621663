import poppinsBold from './Poppins-Bold.ttf';
import poppinsLight from './Poppins-Light.ttf';
import poppinsMedium from './Poppins-Medium.ttf';
import poppinsRegular from './Poppins-Regular.ttf';

export default {
  poppinsBold: [
    {
      src: poppinsBold
    }
  ],
  poppinsLight: [
    {
      src: poppinsLight
    }
  ],
  poppinsMedium: [
    {
      src: poppinsMedium
    }
  ],
  poppinsRegular: [
    {
      src: poppinsRegular
    }
  ]
};
