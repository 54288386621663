import React from 'react';
import PropTypes from 'prop-types';
import styled, { useTheme } from 'styled-components';
import { NavLink } from 'react-router-dom';

import { customUnderline, P, Bold } from '../typography';

const Button = styled(NavLink)`
  cursor: pointer;
  justify-content: center;
  margin: 14px 25px;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(37, 53, 81, 0.2); /* mobile */
`;

const NavButton = ({
  children,
  className,
  exact = false,
  href,
  linkColor = 'blue',
  noActiveStyle = false,
  onClick,
  text,
  to = '/',
  type
}) => {
  const { color } = useTheme();
  const Text = type === 'bold' ? Bold : P;
  return (
    <Button
      as={href && 'a'}
      target={href && '_blank'}
      activeStyle={noActiveStyle ? {} : customUnderline(color.blue)}
      to={to}
      href={href}
      exact={exact}
      className={className}
      onClick={onClick}
      rel={href && 'noopener'}
    >
      {text && (
        <Text color={linkColor} style={{ whiteSpace: 'nowrap' }}>
          {text}
        </Text>
      )}
      {children}
    </Button>
  );
};

NavButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  exact: PropTypes.bool,
  href: PropTypes.string,
  linkColor: PropTypes.string,
  noActiveStyle: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string,
  to: PropTypes.string,
  type: PropTypes.string
};

export default NavButton;
