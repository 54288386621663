import React, { useEffect, useState } from 'react';
import { throttle } from 'underscore';

import { Links, Logo, Wrapper, Line, Hamburger, LogoLink } from './components';
import NavButton from '../NavButton';
import Button from '../Button';
import Menu from '../Menu';

const Header = () => {
  const [showMenu, setShowMenu] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [resizeNav, setResizeNav] = useState(true);

  function toggleIsOpen() {
    setIsOpen(isOpen => {
      document
        .getElementById('root')
        .setAttribute('style', `overflow: ${isOpen ? 'visible' : 'hidden'}`);
      return !isOpen;
    });
  }

  useEffect(() => {
    setShowMenu(window.innerWidth < 950);

    function handleResize() {
      setShowMenu(window.innerWidth < 950);
    }

    function handleScroll() {
      if (window.pageYOffset < 60) {
        setResizeNav(true);
      } else {
        setResizeNav(false);
      }
    }

    const throttleScroll = throttle(handleScroll, 100);

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', throttleScroll);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', throttleScroll);
    };
  }, []);

  return (
    <>
      <Wrapper shouldResize={resizeNav}>
        <LogoLink to="/" noActiveStyle>
          <Logo />
        </LogoLink>
        {showMenu ? (
          <Hamburger onClick={toggleIsOpen}>
            <Line isOpen={isOpen} top />
            <Line isOpen={isOpen} />
          </Hamburger>
        ) : (
          <Links>
            <NavButton text="Home" to="/" exact />
            <NavButton text="About" to="/about" />
            <NavButton text="Contact Us" to="/contact-us" />
            <NavButton text="Events" to="events" />
            <Button
              text="Give"
              href="https://give.cru.org/1076830"
              style={{ marginLeft: 20 }}
            />
          </Links>
        )}
      </Wrapper>
      <Menu onClick={toggleIsOpen} isOpen={isOpen && showMenu} />
    </>
  );
};

export default Header;
