import React from 'react';
import PropTypes from 'prop-types';

import { Container, Small, Large } from './components';
import Button from '../Button';

const Hero = ({ copy, size = 'h1', to, src }) => {
  const Copy = size === 'h1' ? Large : Small;
  return (
    <Container src={src}>
      <Copy>{copy}</Copy>
      {to && <Button inverse large text="Learn More" to={to} />}
    </Container>
  );
};

Hero.propTypes = {
  to: PropTypes.string,
  copy: PropTypes.string,
  src: PropTypes.node.isRequired,
  size: PropTypes.string
};

export default Hero;
