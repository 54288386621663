import React from 'react';
import PropTypes from 'prop-types';

import { Container, Content, Image, Item } from './components';
import { H1, H2 } from '../typography';
import Button from '../Button';

const LargeContent = ({ copy, list, size = 'h1', title, to, src }) => {
  const Copy = size === 'h1' ? H1 : H2;
  return (
    <Container>
      <Content>
        <H2 color="black" marginBottom={36}>
          {title}
        </H2>
        {list && (
          <ul>
            {list.map((item, index) => (
              <li key={index}>
                <Item>{item}</Item>
              </li>
            ))}
          </ul>
        )}
        <Copy>{copy}</Copy>
        {to && <Button large text="Learn More" to={to} />}
      </Content>
      <Image src={src} />
    </Container>
  );
};

LargeContent.propTypes = {
  copy: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  size: PropTypes.string,
  src: PropTypes.node.isRequired
};

export default LargeContent;
