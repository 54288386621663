import React from 'react';
import { Wrapper, Book, NumbersRow, Number, Zero, Verse } from './components';

// Matthew 4:4 "Man shall not live by bread alone, but by every word that comes
// from the mouth of God."

const FourOFour = () => (
  <Wrapper>
    <Book color="black">Matthew</Book>
    <NumbersRow>
      <Number color="black">4</Number>
      <Number color="black">﹕</Number>
      <Number color="black">4</Number>
      <Zero color="black">0</Zero>
    </NumbersRow>
    <Verse color="blue">
      “Man shall not live by bread alone, but by every word that comes from the
      mouth of God.”
    </Verse>
  </Wrapper>
);

export default FourOFour;
