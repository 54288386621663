import styled from 'styled-components';

export const FieldSet = styled.fieldset`
  display: flex;
  border: none;
  flex-direction: column;
  padding: 0;
`;

export const FieldWrapper = styled.div`
  flex-direction: ${p => (p.isBottom ? 'column-reverse' : 'column')};
  margin-bottom: 24px;
`;

export const GenericInput = styled.div`
  background-color: ${p => p.theme.color.offWhite};
  border-radius: 2px;
  border: 1px solid ${p => (p.error ? p.theme.color.error : '#ccc')};
  color: ${p => p.theme.color.black};
  font-family: ${p => p.theme.font.poppinsLight};
  font-size: 0.9rem;
  padding: 12px;

  &:focus {
    outline-width: 1px;
    outline-style: dotted;
    transition: background-color 0.1s ease-in;
    background-color: ${p => p.theme.color.white};
  }

  ::placeholder {
    opacity: 0.9;
  }
`;
