import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { Wrapper, Text } from './components';

const Button = ({
  className,
  text,
  inverse = false,
  large,
  to,
  href,
  style
}) => (
  <div className={className}>
    <Wrapper
      as={to ? NavLink : 'a'}
      href={href}
      inverse={inverse}
      style={style}
      target={href && '_blank'}
      rel={href && 'noopener'}
      to={to}
    >
      <Text large={large}>{text}</Text>
    </Wrapper>
  </div>
);

Button.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  inverse: PropTypes.bool,
  large: PropTypes.bool,
  text: PropTypes.string.isRequired,
  to: PropTypes.string,
  href: PropTypes.string
};

export default Button;
