import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Label from '../Label';
import { GenericInput, FieldWrapper } from '../components';
import { handleChange, fieldType } from '../utils';

const Option = styled.div`
  flex-direction: row;
  align-items: baseline;
`;

const Radio = ({
  field,
  hasError = false,
  isRequired = false,
  options,
  name,
  onChange
}) => (
  <FieldWrapper>
    <Label
      isRequired={isRequired}
      text={name}
      forId={field}
      hasError={hasError}
    />
    {options.map(({ item, value }, index) => (
      <Option key={index}>
        <GenericInput
          onChange={handleChange(onChange)}
          as="input"
          type="radio"
          name={field}
          id={item}
          style={{ marginRight: 10 }}
          value={value}
        />
        <Label text={item} forId={item} />
      </Option>
    ))}
  </FieldWrapper>
);

Radio.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.string.isRequired,
      value: PropTypes.bool
    })
  ).isRequired,
  ...fieldType
};

export default Radio;
