import styled from 'styled-components';
import NavButton from '../../../components/NavButton';
import { customUnderline } from '../../../components/typography';

export const Container = styled.div`
  background-color: ${p => p.theme.color.grey};
  min-height: 1px; /* IE 11 */

  ${p => p.theme.size.padding()};

  @media ${p => p.theme.media.tablet} {
    flex-direction: column;
  }
`;

export const Content = styled.div`
  width: 50%;
  flex-direction: column;
  padding: 0 17px;

  @media ${p => p.theme.media.tablet} {
    width: 100%;
  }
`;

const width = '75%';

export const Image = styled.img`
  min-height: 1px; /* IE 11 */
  align-self: flex-end;
  max-width: ${width};
  max-height: calc(90vh - ${p => p.theme.size.headerHeight}px);

  @media ${p => p.theme.media.tablet} {
    align-self: center;
    margin-bottom: 32px;
    width: 30%;
  }

  @media ${p => p.theme.media.largePhone} {
    display: none;
  }
`;

export const Copy = styled.div`
  width: ${width};
  flex-direction: column;

  @media ${p => p.theme.media.tablet} {
    width: 100%;
  }
`;

export const CustomNavButton = styled(NavButton).attrs({
  type: 'bold'
})`
  height: '100%';
  margin: 0;
  ${p => customUnderline(p.theme.color.blue)};
`;
