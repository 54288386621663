import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { P } from '../typography';

const largePhoneCopy = css`
  @media ${p => p.theme.media.largePhone} {
    margin-bottom: 32px;
    width: 100%;
  }
`;

const Wrapper = styled.div`
  background-color: ${p => p.theme.color.grey};
  justify-content: space-between;

  ${p => p.theme.size.padding()};

  @media ${p => p.theme.media.largePhone} {
    padding-top: 32px;
    flex-direction: column;
  }
`;

const LeftCopy = styled.h3`
  font-family: ${p => p.theme.font.poppinsRegular};
  font-size: 2.2rem;
  line-height: 1.3;
  width: 50%;

  ${largePhoneCopy};
`;

const RightCopy = styled(P)`
  width: 40%;

  ${largePhoneCopy};
`;

const SideBySideCopy = ({ leftCopy, rightCopy }) => (
  <Wrapper>
    <LeftCopy>{leftCopy}</LeftCopy>
    <RightCopy color="black">{rightCopy}</RightCopy>
  </Wrapper>
);

SideBySideCopy.propTypes = {
  leftCopy: PropTypes.string.isRequired,
  rightCopy: PropTypes.string.isRequired
};

export default SideBySideCopy;
