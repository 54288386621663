export default [
  {
    pattern: 'pattern-triangles-md',
    name: 'Example Event',
    date: new Date('May 20 2020 12:00'),
    time: '12:00 PM - 1:00 PM',
    location: 'Gatton Student Center Room 375',
    map:
      'https://www.google.com/maps?q=160+Avenue+of+Champions+Lexington,+KY+40508+United+States',
    calendar:
      'http://www.google.com/calendar/event?action=TEMPLATE&text=April+Faculty+Commons+Lunch&dates=20200401T160000Z/20200401T170000Z&location=160+Avenue+of+Champions%2C+Lexington%2C+KY+40508%2C+United+States',
    ics:
      'https://secure.ministrysync.com/ministrysync/Scheduler/CalURL.php?title=April+Faculty+Commons+Lunch&date=2020-04-01+12%3A00%3A00&end_date=2020-04-01+13%3A00%3A00&location=Gatton+Student+Center+Room+375%20%0D%0A160+Avenue+of+Champions+%0D%0ALexington%2C+KY++40508&url=&calendar_type=app',
    desc: `Faculty Commons will be hosting our monthly lunch gathering. It will be a great time to connect with others who are seeking to live for Christ in Acedemia. We will be taking some time to hear from each other ideas and ways to reach UK with the gospel of Christ!\nLunch will be provided!`,
    register: 'https://secure.ministrysync.com/ministrysync/event/?e=19769 '
  }
];
