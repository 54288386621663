import styled, { css } from 'styled-components';
import NavButton from '../NavButton';

const logo = require('./logo.png');

export const Wrapper = styled.header`
  width: 100%;
  align-items: center;
  background-color: ${p => p.theme.color.white};
  display: flex;
  flex: 0 1 auto;
  justify-content: space-between;
  z-index: 2;

  @media ${p => p.theme.media.desktop} {
    top: 0;
    padding: 0 ${p => p.theme.size.sidePadding};
    height: ${p => p.theme.size.headerHeight}px;
    max-height: ${p =>
      p.shouldResize
        ? p.theme.size.headerHeight
        : p.theme.size.headerHeight / 2 + 12}px;
    position: fixed;
    transition: max-height 0.3s
      ${p => (p.shouldResize ? 'ease-in' : 'ease-out')};
  }

  @media ${p => p.theme.media.largePhone} {
    padding: 16px;
  }

  ${p => p.theme.size.padding()};
`;

export const Logo = styled.img.attrs({
  alt: 'Faculty Commons Logo',
  src: logo
})`
  width: auto;
  max-width: 100%;
  max-height: 65px;

  @media ${p => p.theme.media.largePhone} {
    max-height: 50px;
  }
`;

export const Links = styled.div`
  align-items: center;
  justify-content: space-between;
  min-width: 460px;
`;

export const LogoLink = styled(NavButton)`
  margin: 0;
`;

export const Line = styled.div`
  background-color: ${p => p.theme.color.blue};
  height: 2px;
  width: 40px;
  top: ${p => p.top && 10}px;
  bottom: ${p => !p.top && 10}px;
  position: absolute;
  transition: 0.5s ease-in-out;

  ${p =>
    p.isOpen &&
    css`
      transform: translateY(${p.top ? 9 : -9}px) rotate(${p.top ? 45 : 135}deg);
    `};
`;

export const Hamburger = styled.div`
  position: relative;
  width: 40px;
  flex-direction: column;
  height: 40px;
`;
