import React from 'react';
import PropTypes from 'prop-types';

import { Container, SingleCard, To, Title } from './components';
import { P } from '../typography';

const Cards = ({ content }) => (
  <Container>
    {content.map(({ title, href, copy }, index) => (
      <SingleCard
        key={index}
        outer={index === 0 || index === content.length - 1}
      >
        <Title>
          <To href={href} target="_blank" rel="noopener">
            {title}
          </To>
        </Title>
        <P large>{copy}</P>
      </SingleCard>
    ))}
  </Container>
);

Cards.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      copy: PropTypes.string.isRequired,
      href: PropTypes.string,
      title: PropTypes.string.isRequired
    })
  ).isRequired
};

export default Cards;
