const lambda =
  'https://b1otmwyzef.execute-api.us-east-1.amazonaws.com/default/uk-fc-ses';

export default async function send(data, type) {
  const formattedData = Object.keys(data).reduce(
    (acc, cur) => {
      const { isValid, ...field } = data[cur];
      return { ...acc, [cur]: field.value };
    },
    { type: type }
  );

  const response = await fetch(lambda, {
    method: 'POST',
    body: JSON.stringify(formattedData)
  });
  return response.status === 200;
}
