import React from 'react';
import styled from 'styled-components';

import { Bold, P } from '../../../components/typography';

const Copy = styled(Bold)`
  font-size: 2.8rem;
  line-height: 1.3;
  max-width: 75%;
  text-align: center;

  @media ${p => p.theme.media.largePhone} {
    font-size: 2rem;
    max-width: 100%;
  }
`;

const Wrapper = styled.div`
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%; /* IE 11 */
`;

const Quote = () => (
  <Wrapper>
    <Copy marginBottom={32}>
      “The enormous challenges today facing our nation and the world require men
      and women whose thirst for knowledge is coupled with spiritual wisdom and
      moral character. Faculty Commons understands this!”
    </Copy>
    <P large>— Marla Frederick</P>
  </Wrapper>
);

export default Quote;
